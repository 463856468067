.container-alert{
    background-color: #BB0628;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    padding: 1.2rem;
    position: fixed;
    width: 100vw;
    animation: alert 1s;
    z-index: 1;
}

@keyframes alert{
    0%{top:-100px}
    100%{top:0px}
}

.btn-close{
    border: 0.15rem solid white;
    display: inline;
    float: right;
    border-radius: 50%;
    padding-inline: 0.5rem;
}

.btn-close:hover{
    opacity: 0.8;
}

