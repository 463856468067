.footer{
    padding: 2rem;
    margin: auto;
    margin-top: 4rem;
    padding-top:1rem;
    display: block;
    border-top: solid 1px #e3e3e4;
    font-size: 1rem; 
    max-width: 1232px;
}

.footer a{
    color: #0046BE;
}

.separator{
    padding-inline: 0.5rem;
    color: #E0E6EF;
}