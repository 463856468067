.background{
    width: auto;
    margin: 1rem;
}

.background .progress{
    background-color: #d5d5d5;
}

.progress-text{
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    
}

