.form-body{
    padding-inline: 2rem;
    max-width: 1232px;
    margin: 24px auto;
}

.form-text{
    font-size: 1rem;
    padding-block: 2rem;
    font-weight: bold;
}

.form-text.big{
  font-size: 1.5rem;
}

.form-text.center{
  text-align: center;
}

.button-container{
    margin-top: 1rem;
    display: flex;  
    flex-direction: row-reverse;
}

.form-body .form-button{
    background-color: #0046BE;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    width: 10rem;
    height: 4rem;
    border-radius: 0.5rem;
    border: none;
}

.form-body .form-button:hover{
    background-color: #09409e;
}

.rating{
max-width: fit-content;
max-width: min-content;
margin-inline: auto;
}

.rating-text{
    display: flex;
    justify-content: space-between;
    font-size: 0.7rem;
}

.rating-buttons{
    display: flex;
    justify-content: space-between;
}

.rating-buttons .radio{
    margin-bottom: 2rem;
    font-size: 1rem;
    background-color: #d5d5d5;
    border-radius: 5px;
    padding-inline: 0.5rem;
    margin-inline: 0.1rem;
    border: none;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}



.rating-buttons .radio.checked{
    background-color: #0046BE;
    color: white;
}

.text-input{
    padding: 1rem; 
    width: 100%;
    border: 1px solid #d5d5d5;
    font-size: 1rem;
}

.form-subtext{
    font-size: 0.9rem;
    font-weight: bold;
}

.form-light-subtext{
    font-size: 0.8rem;
}

.radioContainer {
  display: block;
  position: relative;
  padding-left: 4rem;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #eee;
    border-radius: 50%;
  }

  .radioContainer:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .radioContainer input:checked ~ .checkmark {
    background-color: #09409e;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radioContainer input:checked ~ .checkmark:after {
    display: block;
  }

  .radioContainer .checkmark:after {
    top:0.38rem;
    left:0.38rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: white;
  }


  .form-text-thanks{
    font-size: 2.5rem;
    padding-block: 3rem;
    font-weight: bold;
    color: #0046BE;
} 
 