:root {
  --button: #4bbdff;
  --button-hover: #82d2ff;
}

.logo{
  width: 2.5rem;
}

.body{
    padding: 4rem;
}

.main.center-body{
  text-align: center;
  padding-top: 2rem;
}

.easynps_description{
  margin-inline: auto;
  padding: 1rem;
  max-width: 20rem;
}

.button.center-body{
  text-align: center;
  padding-block: 2rem;
}

.button.center-body .learnMore{
  text-align: center;
  background-color: var(--button);
  border: 0px;
  border-radius: 5px;
  padding: 0.5rem;
  padding-inline: 1rem;
  color: white;
}

.button.center-body .learnMore:hover{
  background-color: var(--button-hover);
}

.container_image_description{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.container_image_description.reverse{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap-reverse;
}

.image-container{
  overflow: hidden;
}

.image-container img{
  position: relative;
  width: 50vw;
  max-width: 20rem;
}

.second{
  padding: 2rem;
  text-align: center;
  max-width: 20rem;
}

.banner-home{
    background-image: linear-gradient(#ffffff, #f0f0f0);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.user_button{
  background-color: var(--button);
  border: 0px;
  border-radius: 5px;
  padding: 0.5rem;
  padding-inline: 1rem;
  color: white;
}

.menu_container{
  padding-top: 2rem ;
  text-align: center;
  display: grid;
  place-items: center;
}

.menu_button{
  background-color: var(--button);
  border: 0px;
  border-radius: 5px;
  padding: 0.5rem;
  margin-inline: 0.5rem;
  color: white;
}

.slide_left{
  animation-duration: 0.3s;
  animation-name: slide_left;
  animation-timing-function: ease-out;
  display: flex;
  flex-direction: row;

}

@keyframes slide_left {
  from {
    opacity: 0;
   transform: translateX(10rem) ;
  }

  to {
    opacity: 1;
    transform: translateX(0px)
  }
}