.scan-body{
    margin-inline: 0.4rem;
}


.container-ok{
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin-bottom: 5rem;
}


.container-ok button{
    margin-inline: auto;
    padding: 0.4rem;
    padding-inline: 1.5rem;
    background-color: transparent;
    color: #0046BE;
    border: 1px solid #0046BE;
    border-radius: 5px;
    font-size: 1rem;
 }

 .container-ok button:hover{
     color: white;
     background-color: #0e2f68;
 }


.scan-button{
   margin-inline: auto;
   background-color: #0046BE;
   padding: 0.8rem;
   padding-inline: 1.5rem;
   color: white;
   border: 1px solid ;
   border-radius: 5px;
   font-size: 1rem;
}

.scan-button:hover{
    background-color: #0e2f68;
}

.scan-button.active{
    background-color: #0e2f68;
}

.scan-container{
    display: grid;
    place-items: center;
}

.form-text{
    font-size: 1rem;
    padding-block: 1rem;
    font-weight: bold;
}

.btn_disconnect{
    background-color: transparent;
    border: none;
    padding: 1rem;  
    font-size: 2.5rem;
    color: #0046BE;
    transition: all 0.1s ease-in;
}

.btn_disconnect:hover{
    opacity: 0.9;
    transform: scale(150%);
}


