.radioContainer {
    display: block;
    position: relative;
    padding-left: 4rem;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .radioContainer input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  
  .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 1.5rem;
      width: 1.5rem;
      background-color: #eee;
      border-radius: 50%;
    }
  
    .radioContainer:hover input ~ .checkmark {
      background-color: #ccc;
    }
  
    .radioContainer input:checked ~ .checkmark {
      background-color: #09409e;
    }
  
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
  
    .radioContainer input:checked ~ .checkmark:after {
      display: block;
    }
  
    .radioContainer .checkmark:after {
      top:0.38rem;
      left:0.38rem;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background: white;
    }

   